<template>
  <div class="view-table" aria-live="polite">
    <slot name="table">
      <v-row v-for="(item, n) in getObjectData(data, contentType, isJackpots)" :key="n">
        <v-col
          v-if="item.Title || item.Event || item.Logo || item.Popularity || item['Download Button']"
          cols="12"
          md="12"
          lg="5"
          xl="5"
        >
          <slot v-if="item.Title || item.Event" name="table-item-title">
            <span class="mb-5">
              <smart-link :to="item?.uri" class="mb-2">{{ item.Title }}</smart-link>
            </span>
          </slot>
          <slot name="table-img" :title="item.Logo">
            <ImageReview
              v-if="item.Logo"
              :content-type="item?.Logo?.contentType"
              class="my-2"
              :nid="item?.Logo?.nid"
              :download-nick="item?.Logo?.downloadNick"
              :software-title="item?.Logo?.software"
              :alt="item?.Logo?.alt || item?.Title"
            />
          </slot>

          <Rating v-if="item?.Popularity.value" v-model="item.Popularity.value"></Rating>
          <slot name="table-actions">
            <Button
              v-if="item['Download Button']"
              :download-nick="item['Download Button'].downloadNick || ''"
              :software-title="item['Download Button'].software || ''"
              class="w-100 mt-2"
              text="Play Now"
            />
          </slot>
        </v-col>
        <v-col
          cols="12"
          md="12"
          :lg="item.Logo ? 7 : 12"
          :xl="item.Logo ? 7 : 12"
          :class="item.Logo ? '' : 'mt-3 ml-3'"
        >
          <Taxonomy :taxonomy="item.taxonomy" />
        </v-col>
      </v-row>
    </slot>
  </div>
</template>
<script setup>
import { getObjectData } from "@/lib/tools.js";
const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  isJackpots: {
    type: Boolean,
    default: false,
  },
});
const page = inject("page");
const contentType = unref(page).typename;
</script>
